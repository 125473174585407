<template>
	<div class="performance performance--overview">

		<number-bars class="number-bars--on-red number-bars--full-width" :title="`MBS: Par Value \nNetting Destined`" unit="( in trillions )" :numbers="mbsParValue" />
	</div>
</template>

<script>
import NumberBars from '@/components/NumberBars'

export default {
	name: 'FixedIncome',
	components: {
		NumberBars,
	},
	data: () => ({
		mbsParValue: [
			{
				value: 70,
				label: '2020',
			},
			{
				value: 67,
				label: '2019',
			},
			{
				value: 58.7,
				label: '2018',
				options: {
					decimalPlaces: 1,
				},
			},

		],
	})
}
</script>

<style scoped lang="scss">

</style>
